const navbar = document.querySelector("nav");
const burgerMenuOpen = document.querySelector(".burgerMenu");
const burgerMenuClose = document.querySelector(".closeMenu");
const showMenu = document.querySelector("#mobileMenu");

burgerMenuOpen.addEventListener("click", () => {
  showMenu.style.display = "flex";
  document.querySelector("body").style.overflow = "hidden"
});

burgerMenuClose.addEventListener("click", () => {
  showMenu.style.display = "none";
  document.querySelector("body").style.overflow = "scroll"

});
